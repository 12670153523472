
import { Vue, Component, Ref, Watch } from "vue-property-decorator";
import InitiativeList from "@/components/Initiative/List.vue";
import PageSection from "@/components/Common/PageSection.vue";
import { currentUserStore, zoneStore } from "@/store/typed";
import InitiativeModal from "@/components/Initiative/Modal.vue";
import OrganizationSelect from "@/components/Organization/Select.vue";
import VThrottlerInput from "@/components/Common/VThrottlerInput.vue";
import { Initiative } from "../../models";

@Component({
  components: {
    InitiativeList,
    PageSection,
    InitiativeModal,
    OrganizationSelect,
    VThrottlerInput,
  },
})
export default class Initiatives extends Vue {
  private showCompletedInitiatives = true;

  @Ref() readonly initiativeModal!: InitiativeModal;
  @Ref() readonly initiativeList!: InitiativeList;

  private organizationId: string | null = null;
  private initiativeTerm: string | null = null;

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  get currentUser() {
    return currentUserStore.currentUser;
  }

  get isAdmin() {
    return currentUserStore.user?.isApplicationAdmin;
  }

  get filter() {
    const organization = this.organization === "all" ? null : this.organization;

    const data: any = {
      organization: organization,
      term: this.initiativeTerm,
    };

    if (!this.showCompletedInitiatives) {
      data.not_status = "completed";
    }
    return data;
  }

  get realmFilter() {
    return { realm: zoneStore.realm?.id || "" };
  }

  get initiativeStatus() {
    return this.showCompletedInitiatives ? null : "completed";
  }

  get title() {
    return this.isAdmin ? "Gestione Attivazione" : "Iniziative";
  }

  get organization() {
    return this.organizationId;
  }

  private openCreateInitiativeModal() {
    this.initiativeModal.show();
  }

  private reloadList(initiative: Initiative) {
    this.$router.push({
      name: "admin_initiative_show",
      params: { id: initiative.id },
    });
    this.initiativeList.load();
  }

  onOrganizationSelected(id: string) {
    this.organizationId = id;
  }

  get isAdministrative() {
    return currentUserStore.currentUser?.isAdministrative;
  }
}
