import Initiative from "@/models/Initiative";
import { currentUserStore, httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_API_URL } from "../../utils/Constants";
import * as qs from "qs";
export enum InitiativeTags {
  InitiativeShow = "InitiativeShow",
  InitiativesIndex = "InitiativesIndex",
  InitiativeUpdate = "InitiativeUpdate",
}

@Component
export default class InitiativeMixin extends Vue {
  getInitiative(id: string): Promise<Initiative> {
    const url = `${SD_BASE_API_URL}/initiatives/${id}`;

    return httpStore.request({
      tag: InitiativeTags.InitiativeShow,
      url: url,
      method: "GET",
    }) as Promise<Initiative>;
  }

  getInitiatives(page: number | null | string, filter: any) {
    const url = `${SD_BASE_API_URL}/initiatives`;

    const data = {
      page: page,
      filter: filter,
    };

    return httpStore.request({
      tag: InitiativeTags.InitiativesIndex,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<Initiative[]>;
  }

  updateInitiative(data: Partial<Initiative>): Promise<Initiative> {
    const url = `${SD_BASE_API_URL}/initiatives/${data.id}`;

    return httpStore.request({
      tag: InitiativeTags.InitiativeUpdate,
      url: url,
      method: "PUT",
      data: data,
    }) as Promise<Initiative>;
  }
}
