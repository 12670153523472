
import { Vue, Component, Prop, Ref } from "vue-property-decorator";

import InitiativeCreateForm from "@/components/Initiative/CreateForm.vue";
import VModal from "@monade/vue-components/src/components/VModal.vue";
import { Initiative } from "../../models";

@Component({
  components: {
    VModal,
    InitiativeCreateForm,
  },
})
export default class InitiativeModal extends Vue {
  @Ref() readonly modal!: VModal;
  @Ref() readonly createForm!: InitiativeCreateForm;

  show() {
    (this.modal as any).show();
  }

  hide() {
    (this.modal as any).hide();
  }

  private onCreated(initiative: Initiative) {
    this.$emit("created", initiative);
    this.hide();
  }

  private async onUpload() {
    this.createForm.create();
  }
}
