
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { Initiative } from "@/models";
import VPaginator from "@/components/Common/VPaginator.vue";
import InitiativeCard from "@/components/Initiative/Card.vue";
import InitiativeMixin, { InitiativeTags } from "@/mixins/http/InitiativeMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { RegisterHttp } from "@/utils/Decorators";
import EmptyView from "@/components/Common/EmptyView.vue";
import RequestData from "@/store/types/RequestData";
import { currentUserStore, httpStore, zoneStore } from "@/store/typed";
const indexTag = InitiativeTags.InitiativesIndex;

@Component({
  components: {
    InitiativeCard,
    EmptyView,
    VPaginator,
  },
})
export default class InitiativeList extends Mixins(InitiativeMixin) {
  @RegisterHttp(indexTag) readonly indexRequest!: RequestData;

  @Prop() readonly filter?: any;

  private items: Initiative[] = [];
  private error = false;
  private page = 0;

  mounted() {
    this.load();
  }

  get currentUser() {
    return currentUserStore.currentUser;
  }

  get isAdministrative() {
    return this.currentUser?.isAdministrative;
  }

  get empty() {
    return !this.loading && !this.items.length;
  }

  onPageChanged(page: number) {
    this.page = page;
    this.load();
  }

  @Watch("filter", { deep: true })
  filterByStatus() {
    this.load();
  }

  goToDetail(item: Initiative) {
    let initiativeName = this.isAdministrative
      ? "admin_initiative_show"
      : "initiative_show";
    let contractName = "admin_contract_show";
    this.$router.push({
      name: item.step === "in_progress" ? contractName : initiativeName,
      params: {
        id: item.step === "in_progress" ? item.contract.id : item.id,
      },
    });
  }

  get loading() {
    return this.indexRequest?.loading;
  }

  get meta() {
    return this.indexRequest?.meta;
  }

  async load() {
    if (this.currentUser?.isOrganizationUser) {
      this.filter.organization = this.currentUser?.organization?.id;
    }
    this.filter.realm = zoneStore.realm?.id || "";

    const [data, errors] = await safeAsync(
      this.getInitiatives(this.page, this.filter)
    );

    if (!errors) {
      this.items = data;
    } else {
      this.error = true;
    }
  }
}
