import Initiative from "@/models/Initiative";
import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_API_URL } from "@/utils/Constants";

export enum InitiativeAdminTags {
  InitiativeCreate = "InitiativeCreate",
  InitiativeUpdate = "InitiativeUpdate",
  InitiativeDestroy = "InitiativeDestroy",
  InitiativeClose = "InitiativeClose",
}

@Component
export default class InitiativesAdminMixin extends Vue {
  createInitiative(
    name: string,
    organizationId: string,
    contactPerson: string,
    additionalInformation: string
  ): Promise<Initiative> {
    const url = `${SD_BASE_API_URL}/admin/initiatives`;

    const data = {
      name: name,
      organization_id: organizationId,
      contact_person: contactPerson,
      metadata: {
        additional_information: additionalInformation,
      },
    };

    return httpStore.request({
      tag: InitiativeAdminTags.InitiativeCreate,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<Initiative>;
  }

  closeInitiative(id: string): Promise<Initiative> {
    const url = `${SD_BASE_API_URL}/admin/initiatives/${id}/close`;

    const data = {};

    return httpStore.request({
      tag: InitiativeAdminTags.InitiativeClose,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<Initiative>;
  }

  updateInitiative(
    id: string,
    name?: string,
    organizationId?: string,
    userId?: string
  ): Promise<Initiative> {
    const url = `${SD_BASE_API_URL}/admin/initiatives/${id}`;

    const data = {
      name: name,
      organization_id: organizationId,
      user_id: userId,
    };

    return httpStore.request({
      tag: InitiativeAdminTags.InitiativeUpdate,
      url: url,
      method: "PUT",
      data: data,
    }) as Promise<Initiative>;
  }

  deleteInitiative(id: string): Promise<Initiative> {
    const url = `${SD_BASE_API_URL}/admin/initiatives/${id}`;

    return httpStore.request({
      tag: InitiativeAdminTags.InitiativeDestroy,
      url: url,
      method: "DELETE",
    }) as Promise<Initiative>;
  }
}
