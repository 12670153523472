
import { Component, Mixins } from "vue-property-decorator";
import { InitiativeAdminTags } from "@/mixins/http/admin/InitiativeAdminMixin";
import WithErrors from "@/directives/WithErrors";
import OrganizationSelect from "@/components/Organization/Select.vue";
import InitiativesAdminMixin from "@/mixins/http/admin/InitiativeAdminMixin";
import { safeAsync } from "@/utils/AsyncUtil";

@Component({
  directives: {
    WithErrors,
  },
  components: {
    OrganizationSelect,
  },
})
export default class InitiativeCreateForm extends Mixins(
  InitiativesAdminMixin
) {
  private name = "";
  private contactPerson = "";
  private additionalInformation = "";

  private organizationId: string | null = null;

  get createTag() {
    return InitiativeAdminTags.InitiativeCreate;
  }

  private onOrganizationSelected(id: string) {
    this.organizationId = id;
  }

  async create() {
    const [response, errors] = await safeAsync(
      this.createInitiative(
        this.name,
        this.organizationId,
        this.contactPerson,
        this.additionalInformation
      )
    );

    if (response) {
      this.$emit("created", response);
    }
  }
}
