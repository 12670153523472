
import { Component, Prop, Vue } from "vue-property-decorator";
import { Contract, Initiative, User } from "../../models";
import moment from "moment";
import { currentUserStore } from "@/store/typed";

@Component({})
export default class InitiativeCard extends Vue {
  @Prop({ required: true }) readonly value!: Initiative;

  get contract(): Contract {
    return this.value?.contract;
  }
  get currentUser(): User {
    return currentUserStore.currentUser;
  }

  get lastDocStatus(): string {
    return this.value.lastUploadedDocument?.statusLabel(this.currentUser);
  }

  get lastDocDate(): string {
    return moment(this.value.lastUploadedDocument?.attachedAt)
      .format("DD MMM YYYY")
      .toUpperCase();
  }

  get contractStartDate(): string {
    return moment(this.contract.startDate).format("DD MMM YYYY").toUpperCase();
  }

  get contractEndDate(): string {
    return moment(this.contract.endDate).format("DD MMM YYYY").toUpperCase();
  }

  get expiring() {
    return (
      this.isAdministrative &&
      this.value.lastUploadedDocument?.expiresIn &&
      this.value.lastUploadedDocument?.expiresIn <= 5
    );
  }

  get lastDocVersion(): number {
    return this.value.lastUploadedDocument?.version;
  }
  get closed(): boolean {
    return this.value.isClosed;
  }

  get lastDocName(): string {
    return this.value.lastUploadedDocument?.name ?? "Nessun documento";
  }

  get isAdministrative(): boolean {
    return currentUserStore.currentUser?.isAdministrative;
  }

  bgClass(value: Initiative) {
    return `bg-${value.stepClass}`;
  }

  get isExecutiveContract(): boolean {
    return this.value.status === "executive_contracts";
  }

  get isCompleted(): boolean {
    return this.value?.contract?.isCompleted;
  }
}
