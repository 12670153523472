import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=079d23ad&scoped=true"
import script from "./_id.vue?vue&type=script&lang=ts"
export * from "./_id.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "079d23ad",
  null
  
)

export default component.exports